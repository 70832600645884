
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class SupervisionService extends BaseApi {


    constructor(){
        super();
    }


    async index(date = "", warehouse)
    {
        return this.http.get(`${this.baseUrl}/supervision/${date}/${warehouse}`)
        .catch(this.handleErrorResponse);
    }

    exportSupervision(warehouse, startDate, endDate)
    {
        return this.http.get(`${this.baseUrl}/export/supervision/${startDate}/${endDate}/${warehouse}`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

}