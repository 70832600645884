const permissions = {
    consumables:{root:false,index:false,store:false,show:false,edit:false,delete:false},
    equipments:{root:false,index:false,store:false,show:false,edit:false,delete:false},
    entryOrders:{root:false,index:false,store:false,show:false,edit:false,delete:false},
    suppliers:{root:false,index:false,store:false,show:false,edit:false,delete:false},
    purchaseOrders:{root:false,index:false,store:false,show:false,edit:false,delete:false},
    requisitions:{root:false,index:false,store:false,show:false,edit:false,delete:false},
    prospects:{root:false,index:false,store:false,show:false,edit:false,delete:false},
    fee:{root:false,index:false,store:false,show:false,edit:false,delete:false},
    clients:{root:false,index:false,store:false,show:false,edit:false,delete:false},
    clientContacts:{root:false,index:false,store:false,show:false,edit:false,delete:false},
    services:{root:false,index:false,store:false,show:false,edit:false,delete:false},
    quotations:{root:false,index:false,store:false,show:false,edit:false,delete:false},
    saleOrders:{root:false,index:false,store:false,show:false,edit:false,delete:false, update_amount:false},
    billings:{root:false,index:false,show:false, store: false, delete:false},
    warehouses:{root:false,index:false,store:false,show:false,edit:false,delete:false},
    banks:{root:false,index:false,store:false,show:false,edit:false,delete:false},
    departments:{root:false,index:false,store:false,show:false,edit:false,delete:false},
    packagings:{root:false,index:false,store:false,show:false,edit:false,delete:false},
    types:{root:false,index:false,store:false,show:false,edit:false,delete:false},
    units:{root:false,index:false,store:false,show:false,edit:false,delete:false},
    roles:{root:false,index:false,store:false,show:false,edit:false,delete:false},
    users:{root:false,index:false,store:false,show:false,edit:false,delete:false},
    supplies:{root:false,index:false,store:false,show:false,edit:false,delete:false},
    supervisions:{root:false,index:false, show:false},
    dashboards: {root:false, show: false}
};

export default permissions;
