<template>
    <v-btn :disabled="props.disabled" :color="props.color ? `${props.color}`: 'primary'" :dark="props.darkMode" :class="`${props.classes}`" :loading="props.loading" elevation="0" :block="props.block" :to="{ name: props.to }" @click="props.click" >
        <div :style="props.icon ? '' : 'justify-content:center'" :class="`primary-button button ${props.textColor ? `${props.textColor}--text text--darken-${props.textColorLevel == undefined ? '1':props.textColorLevel}` : 'grey--text text--darken-3'}`" >
            <p class="mb-0 pl-1 center-text">{{props.text}}</p>
            <template v-if="props.icon">
               &nbsp;&nbsp;<v-icon :class="`${props.iconClass}`">{{props.icon}}</v-icon>
            </template>
            
        </div>
       
    </v-btn>
</template>
<script>
    export default {
        name: 'primaryButton',
        props: ['props']
    }
</script>

<style lang="scss">
    .primary-button{
        
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        
        // min-width: 110px;
    }
</style>