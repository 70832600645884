
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class ConsumableService extends BaseApi {


    constructor(){
        super();
    }


    async list()
    {
        return this.http.get(`${this.baseUrl}/consumables`)
        .catch(this.handleErrorResponse);
    }


    async get(slug)
    {
        return this.http.get(`${this.baseUrl}/consumables/${slug}/edit`)
        .catch(this.handleErrorResponse);
    }

    async show(slug)
    {
        return this.http.get(`${this.baseUrl}/consumables/${slug}/show`)
        .catch(this.handleErrorResponse);
    }


    async store(data)
    {
        return this.http.post(`${this.baseUrl}/consumables/store`,data)
        .catch(this.handleErrorResponse);
    }

    async storeOutput(id, data)
    {
        return this.http.post(`${this.baseUrl}/outputs/${id}/consumable/store`,data)
        .catch(this.handleErrorResponse);
    }


    async update(slug, data)
    {
        return this.http.put(`${this.baseUrl}/consumables/${slug}/update`, data)
        .catch(this.handleErrorResponse);
    }



    async delete(slug)
    {
        return this.http.delete(`${this.baseUrl}/consumables/${slug}/delete`)
        .catch(this.handleErrorResponse);
    }

}