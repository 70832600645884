
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class WarehouseService extends BaseApi {


    constructor(){
        super();
    }


    async list()
    {
        return this.http.get(`${this.baseUrl}/warehouses`)
        .catch(this.handleErrorResponse);
    }


    async get(slug)
    {
        return this.http.get(`${this.baseUrl}/warehouses/${slug}/edit`)
        .catch(this.handleErrorResponse);
    }


    async store(data)
    {
        return this.http.post(`${this.baseUrl}/warehouses/store`,data)
        .catch(this.handleErrorResponse);
    }


    async update(slug, data)
    {
        return this.http.post(`${this.baseUrl}/warehouses/${slug}/update`, data)
        .catch(this.handleErrorResponse);
    }



    async delete(slug)
    {
        return this.http.delete(`${this.baseUrl}/warehouses/${slug}/delete`)
        .catch(this.handleErrorResponse);
    }


    async getSelect()
    {
        return this.http.get(`${this.baseUrl}/getWarehouses`)
        .catch(this.handleErrorResponse);
    }


    async getByProject(project)
    {
        return this.http.get(`${this.baseUrl}/getClientProductWarehouse/${project}`)
        .catch(this.handleErrorResponse);
    }
}