
export default class TokenManager{

    //appTokenName = "access_token_rrhh";

    /**
     * Returns localStorage token
     * @param {string} num a number 
     */
    static getToken()
    {
       return localStorage.getItem("access_token_rrhh");
    }

    /**
     * Save app token
     * @param {string} token 
     */
    static setToken(token)
    {   
        localStorage.setItem("access_token_rrhh", token);
    }

    /**
     * Delete app token
     */
    static removeToken()
    {
        localStorage.removeItem("access_token_rrhh");
    }
    
}
