
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class ClientService extends BaseApi {


    constructor(){
        super();
    }


    async list()
    {
        return this.http.get(`${this.baseUrl}/clients`)
        .catch(this.handleErrorResponse);
    }


    async get(slug)
    {
        return this.http.get(`${this.baseUrl}/clients/${slug}/edit`)
        .catch(this.handleErrorResponse);
    }


    async store(data)
    {
        return this.http.post(`${this.baseUrl}/clients/store`,data)
        .catch(this.handleErrorResponse);
    }


    async update(slug, data)
    {
        return this.http.put(`${this.baseUrl}/clients/${slug}/update`, data)
        .catch(this.handleErrorResponse);
    }

    async validateUpdate(slug, data)
    {
        // /clients/${clientSlug}/validateUpdate
        return this.http.put(`${this.baseUrl}/clients/${slug}/validateUpdate`, data)
        .catch(this.handleErrorResponse);
    }



    async delete(slug)
    {
        return this.http.delete(`${this.baseUrl}/clients/${slug}/delete`)
        .catch(this.handleErrorResponse);
    }


    async getSelect(warehouse)
    {
        return this.http.get(`${this.baseUrl}/getClients/${warehouse}`)
        .catch(this.handleErrorResponse);
    }

    ///getAllClients
    async getSelectAll()
    {
        return this.http.get(`${this.baseUrl}/getAllClients`)
        .catch(this.handleErrorResponse);
    }

    async getProjectsSelect(slug, warehouse)
    {
        return this.http.get(`${this.baseUrl}/getProjects/${slug}/${warehouse}`)
        .catch(this.handleErrorResponse);
    }

    async getSelectEON(warehouse_id)
    {
        return this.http.get(`${this.baseUrl}/getClientEon/${warehouse_id}`)
        .catch(this.handleErrorResponse);
    }

}