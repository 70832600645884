
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class ScheduleService extends BaseApi {


    constructor(){
        super();
    }


    async list()
    {
        return this.http.get(`${this.baseUrl}/schedules`)
        .catch(this.handleErrorResponse);
    }


    async get(sku)
    {
        return this.http.get(`${this.baseUrl}/schedules/${sku}/edit`)
        .catch(this.handleErrorResponse);
    }

    async show(sku)
    {
        return this.http.get(`${this.baseUrl}/schedules/${sku}/show`)
        .catch(this.handleErrorResponse);
    }


    async store(data)
    {
        return this.http.post(`${this.baseUrl}/schedules/store`,data)
        .catch(this.handleErrorResponse);
    }


    async update(sku, data)
    {
        return this.http.put(`${this.baseUrl}/schedules/${sku}/update`, data)
        .catch(this.handleErrorResponse);
    }



    async delete(sku)
    {
        return this.http.delete(`${this.baseUrl}/schedules/${sku}/delete`)
        .catch(this.handleErrorResponse);
    }

}