
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class SaleOrderService extends BaseApi {


    constructor(){
        super();
    }


    async list(warehouse)
    {
        return this.http.get(`${this.baseUrl}/saleOrders/${warehouse}`)
        .catch(this.handleErrorResponse);
    }


    async get(sku)
    {
        return this.http.get(`${this.baseUrl}/saleOrders/${sku}/edit`)
        .catch(this.handleErrorResponse);
    }


    async store(data)
    {
        return this.http.post(`${this.baseUrl}/saleOrders/store`,data)
        .catch(this.handleErrorResponse);
    }


    async update(sku, data)
    {
        return this.http.put(`${this.baseUrl}/saleOrders/${sku}/update`, data)
        .catch(this.handleErrorResponse);
    }



    async delete(sku)
    {
        return this.http.delete(`${this.baseUrl}/saleOrders/${sku}/delete`)
        .catch(this.handleErrorResponse);
    }



    async getBillings(warehouse, project, startDate, endDate)
    {
        return this.http.get(`${this.baseUrl}/getSaleOrdersBilling/${warehouse}/${project}/${startDate}/${endDate}`)
        .catch(this.handleErrorResponse);
    }


    async getProductActivity(warehouse)
    {
        return this.http.get(`${this.baseUrl}/getProductActivity/${warehouse}`)
        .catch(this.handleErrorResponse);
    }

    async storeProductActivity(warehouse, data)
    {
        return this.http.post(`${this.baseUrl}/supervision/product-activity/${warehouse}`, data)
        .catch(this.handleErrorResponse);
    }

}