import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
import es from 'vuetify/lib/locale/es';
Vue.use(Vuetify);

export default new Vuetify({
    lang:{
      locales: {es},
      current: 'es',
    },
    theme: {
        dark:false,
        options: {
          customProperties: true
        },
        themes: {
          light: {
            primary: "#FCBC10",
            secondary: "#444444",
            accent: '#c2a925',
            success: "#6cc822",
            error: '#de0000',
            info: '#2196F3',
            warning: colors.red,
            white: "#fff",
            caution: "#ffb40a",
            loginText: "#000929",
            cautionButton: colors.amber.darken1,

            inputsBackground: "#f0f0f0",

            normalText: colors.shades.black,
            
            customBlue: "#003143",
            

            background: "#f7f7f7",



            sidebarPrimary: "#2c2e2e",
            sidebarDetail1: "#363838",
            sidebarDetail2: "#3e4040",
            sidebarDetail3: "#454747",
            sidebarDetail4: "#4a4c4c",
            sidebarText: colors.shades.white,
            sidebarTextActive: colors.shades.black,

            loginBtn: "#1E1E1E"
          },
          dark: {
            primary: "#ffdf31",
            secondary: '#E91E63',


            sidebarText: colors.shades.white,
            sidebarTextActive: colors.shades.white,
          },
        },
      },
});
