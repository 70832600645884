
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class CatalogService extends BaseApi {


    constructor(){
        super();
    }

    /**
     * ! SELECTS
     * 
     */
    
   
   
}