
import VueGatesPlugin from '../plugins/vue-gates';
import store from '../store';

export default function VueGateMiddleware({to,next}) {
    let watcher = setInterval(() => {
        if (store.state.userData != null) {
            if(checkPermission(to)){
                console.log("yes permission")
                next();
            }
            else{
                console.log("no permission")
                next('/');
            }
            clearInterval(watcher);
        }
    }, 100);
   
}







function checkPermission(params) {
    return VueGatesPlugin.checkRoutePermission(params);
}