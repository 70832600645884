
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class FeeService extends BaseApi {


    constructor(){
        super();
    }

    async list(warehouse)
    {
        return this.http.get(`${this.baseUrl}/fees/${warehouse}`)
        .catch(this.handleErrorResponse);
    }

    async get(sku)
    {
        return this.http.get(`${this.baseUrl}/fees/${sku}/edit`)
        .catch(this.handleErrorResponse);
    }

    async store(data)
    {
        return this.http.post(`${this.baseUrl}/fees/store`,data)
        .catch(this.handleErrorResponse);
    }

    async update(sku, data)
    {
        return this.http.put(`${this.baseUrl}/fees/${sku}/update`, data)
        .catch(this.handleErrorResponse);
    }

  

    async delete(sku)
    {
        return this.http.delete(`${this.baseUrl}/fees/${sku}/delete`)
        .catch(this.handleErrorResponse);
    }

    async edit(sku)
    {
        return this.http.get(`${this.baseUrl}/fees/${sku}/edit`)
        .catch(this.handleErrorResponse);
    }


    pdf(sku)
    {
        return this.http.get(`${this.baseUrl}/fees/${sku}/pdf`)
        .catch(this.handleErrorResponse);
    }

    async byProspect(prospect)
    {
        return this.http.get(`${this.baseUrl}/getFees/${prospect}`)
        .catch(this.handleErrorResponse);
    }

    async getDetails(fee)
    {
        return this.http.get(`${this.baseUrl}/getFeeDetails/${fee}`)
        .catch(this.handleErrorResponse);
    }


}