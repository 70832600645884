
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class PurchaseOrderService extends BaseApi {


    constructor(){
        super();
    }

    async list()
    {
        return this.http.get(`${this.baseUrl}/purchaseOrders`)
        .catch(this.handleErrorResponse);
    }

    async store(data)
    {
        return this.http.post(`${this.baseUrl}/purchaseOrders/store`,data)
        .catch(this.handleErrorResponse);
    }

    async storePayment(sku, data)
    {
        return this.http.post(`${this.baseUrl}/purchaseOrders/payment/${sku}/store`, data)
        .catch(this.handleErrorResponse);
    }

    async delete(sku)
    {
        return this.http.delete(`${this.baseUrl}/purchaseOrders/${sku}/delete`)
        .catch(this.handleErrorResponse);
    }

    async edit(sku)
    {
        return this.http.get(`${this.baseUrl}/purchaseOrders/${sku}/edit`)
        .catch(this.handleErrorResponse);
    }

    async update(id, data)
    {
        return this.http.put(`${this.baseUrl}/purchaseOrders/${id}/update`, data)
        .catch(this.handleErrorResponse);
    }

    async show(sku)
    {
        return this.http.get(`${this.baseUrl}/purchaseOrders/${sku}/show`)
        .catch(this.handleErrorResponse);
    }

    pdf(sku)
    {
        return this.http.get(`${this.baseUrl}/getPurchasePDF/${sku}`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    async getSelect()
    {
        return this.http.get(`${this.baseUrl}/getPurchaseOrder`)
        .catch(this.handleErrorResponse);
    }

    
    async getDetails(sku)
    {
        return this.http.get(`${this.baseUrl}/getPurchaseDetails/${sku}`)
        .catch(this.handleErrorResponse);
    }




}