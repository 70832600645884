
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class ServiceService extends BaseApi {


    constructor(){
        super();
    }


    async list()
    {
        return this.http.get(`${this.baseUrl}/services`)
        .catch(this.handleErrorResponse);
    }


    async get(slug)
    {
        return this.http.get(`${this.baseUrl}/services/${slug}/edit`)
        .catch(this.handleErrorResponse);
    }


    async store(data)
    {
        return this.http.post(`${this.baseUrl}/services/store`,data)
        .catch(this.handleErrorResponse);
    }


    async update(slug, data)
    {
        return this.http.put(`${this.baseUrl}/services/${slug}/update`, data)
        .catch(this.handleErrorResponse);
    }



    async delete(slug)
    {
        return this.http.delete(`${this.baseUrl}/services/${slug}/delete`)
        .catch(this.handleErrorResponse);
    }


    async getSelect()
    {
        return this.http.get(`${this.baseUrl}/getServices`)
        .catch(this.handleErrorResponse);
    }

    //
    async byClientProject(client_id, project_id)
    {
        return this.http.get(`${this.baseUrl}/getClientServices/${client_id}/${project_id}`)
        .catch(this.handleErrorResponse);
    }
   
}