
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class TypeService extends BaseApi {


    constructor(){
        super();
    }


    async list()
    {
        return this.http.get(`${this.baseUrl}/catalogs/Type`)
        .catch(this.handleErrorResponse);
    }


    async get(slug)
    {
        return this.http.get(`${this.baseUrl}/catalogs/${slug}/edit`)
        .catch(this.handleErrorResponse);
    }

 
 

    async store(data)
    {
        return this.http.post(`${this.baseUrl}/catalogs/store`,data)
        .catch(this.handleErrorResponse);
    }


    async update(slug, data)
    {
        return this.http.put(`${this.baseUrl}/catalogs/${slug}/update`, data)
        .catch(this.handleErrorResponse);
    }



    async delete(slug)
    {
        return this.http.delete(`${this.baseUrl}/catalogs/${slug}/delete`)
        .catch(this.handleErrorResponse);
    }


    async getSelect()
    {
        return this.http.get(`${this.baseUrl}/getCatalogs/Type`)
        .catch(this.handleErrorResponse);
    }

}