
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class EntryOrderService extends BaseApi {


    constructor(){
        super();
    }


    async list()
    {
        return this.http.get(`${this.baseUrl}/entryOrders`)
        .catch(this.handleErrorResponse);
    }


    async show(slug)
    {
        return this.http.get(`${this.baseUrl}/entryOrders/${slug}/show`)
        .catch(this.handleErrorResponse);
    }


    async store(data)
    {
        return this.http.post(`${this.baseUrl}/entryOrders/store`,data)
        .catch(this.handleErrorResponse);
    }


}