
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class DashboardService extends BaseApi {


    constructor(){
        super();
    }


    async quantityServicesByClient(client, project, year, month, page = 1)
    {
        return this.http.get(`${this.baseUrl}/quantity_services_for_client/${client}/${project}/${year}/${month}?page=${page}&perPage=10`)
        .catch(this.handleErrorResponse);
    }


    async amountServicesByClient(client, project, year, month, page)
    {
        return this.http.get(`${this.baseUrl}/amount_services_for_client/${client}/${project}/${year}/${month}?page=${page}&perPage=10`)
        .catch(this.handleErrorResponse);
    }

    async billingPeriodByClient(client, project, year, month)
    {
        return this.http.get(`${this.baseUrl}/billing_per_period/${client}/${project}/${year}/${month}`)
        .catch(this.handleErrorResponse);
    }


    async billingPeriodCostByClient(client, project, year, month)
    {
        return this.http.get(`${this.baseUrl}/billing_per_period_and_cost/${client}/${project}/${year}/${month}`)
        .catch(this.handleErrorResponse);
    }


  
}