<template>
    <div>
        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="País"
                    :loading="loadingCountries"
                    :valueInput="countryValue"
                    :rule="rule"
                    :items="serverItems.countries"
                    @valueChange="(v) => {countryValue = v; this.getStates(v); delete serverErrors.address_code}"
                    data-cy="country-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Estado"
                    :loading="loadingStates"
                    :valueInput="stateValue"
                    :rule="rule"
                    :items="serverItems.states"
                    @valueChange="(v) => {stateValue = v; this.getCities(countryValue, v); delete serverErrors.address_code}"
                    data-cy="state-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Municipio"
                    :loading="loadingCities"
                    :valueInput="value"
                    :rule="rule"
                    :items="serverItems.cities"
                    @valueChange="(v) => {value = v; delete serverErrors.address_code}"
                    data-cy="city-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
    </div>
       
    
</template>
<script>
    export default {
        props: ['valueInput', 'rule', 'label', 'items', 'loading', 'disabled', 'placeholder', 'prependInnerIcon', 'readonly', 'clearable', 'errorMessages', 'serverErrors'],
        data(){
            return {
                loadingCountries:false,
                loadingStates:false,
                loadingCities:false,
                countryValue: "",
                stateValue: "",

                serverItems:{
                    countries: [],
                    states: [],
                    cities: [],

                }
            }
        },
        computed: {
            value: {
                get: function() {
                    return this.valueInput
                },
                set: function(value) {
                    this.$emit('valueChange', value)
                }
            },
       
        
        },
        mounted(){
            
            this.getCountries();

            if(this.valueInput != "")
                this.getCityDetails(this.valueInput)
        },
        methods:{
            onChange(val){
                this.$emit('onChanged', val)
            },
            getCityDetails(code)
            {
                if(code == "" || code == undefined || code == null)
                    return

                this.loadingCountries = true;
                this.$api.addressApi.getCity(code)
                    .then((resp) => {
                        this.countryValue = resp.data.city.country_code;
                        this.stateValue = resp.data.city.state_code;
                        this.getStates(this.countryValue);
                        this.getCities(this.countryValue, this.stateValue);
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.loadingCountries = false;
                    })
                ;
            },
            getCountries()
            {
                
                this.loadingCountries = true;
                this.$api.addressApi.getCountries()
                    .then((resp) => {
                        this.serverItems.countries = resp.data;
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.loadingCountries = false;
                    })
                ;
            },

            getStates(country)
            {
                if(country == "")
                    return;


                this.loadingStates = true;
                this.$api.addressApi.getStates(country)
                    .then((resp) => {
                        this.serverItems.states = resp.data;
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.loadingStates = false;
                    })
                ;
            },

            getCities(country, state)
            {
                if(country == "" || state == "")
                    return;


                this.loadingCities = true;
                this.$api.addressApi.getCities(country, state)
                    .then((resp) => {
                        this.serverItems.cities = resp.data.map(item => {
                            return {value: item.value+"", text: item.text}
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.loadingCities = false;
                    })
                ;
            },
        }
    }
    
</script>

<style lang="scss">


    .alternative{
        &__container{
            //padding-left: 10px;
            border: $lineColor-1 1px solid;
            display: flex;
            // align-items: center;
            
        }

        &__label{
            cursor: default;
            min-width: fit-content;
            &--text{
                //font-weight: bold !important;

            }
        }

        &__input{
            // margin-left: 10px;
            width: -webkit-fill-available;
           
        }
    }

    .form-input{
        width: 100%;
        .v-input__control .v-input__slot input{
            //margin-bottom: 8px;
            margin-top: 0px !important;
            padding: 0;
        }
    }

   
</style>