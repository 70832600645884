
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class QuotationService extends BaseApi {


    constructor(){
        super();
    }

    async list(warehouse)
    {
        return this.http.get(`${this.baseUrl}/quotations/${warehouse}`)
        .catch(this.handleErrorResponse);
    }

    async get(sku)
    {
        return this.http.get(`${this.baseUrl}/quotations/${sku}/edit`)
        .catch(this.handleErrorResponse);
    }

    async store(data)
    {
        return this.http.post(`${this.baseUrl}/quotations/store`,data)
        .catch(this.handleErrorResponse);
    }

    async update(sku, data)
    {
        return this.http.put(`${this.baseUrl}/quotations/${sku}/update`, data)
        .catch(this.handleErrorResponse);
    }

  

    async delete(sku)
    {
        return this.http.delete(`${this.baseUrl}/quotations/${sku}/delete`)
        .catch(this.handleErrorResponse);
    }

    async edit(sku)
    {
        return this.http.get(`${this.baseUrl}/quotations/${sku}/edit`)
        .catch(this.handleErrorResponse);
    }


    pdf(sku)
    {
        return this.http.get(`${this.baseUrl}/quotations/${sku}/pdf`)
        .catch(this.handleErrorResponse);
    }


    getSelectByProject(project_id)
    {
        return this.http.get(`${this.baseUrl}/getQuotations/${project_id}`)
        .catch(this.handleErrorResponse);
    }


    getDetails(quotation_id)
    {
        return this.http.get(`${this.baseUrl}/getQuotationDetails/${quotation_id}`)
        .catch(this.handleErrorResponse);
    }

   

}