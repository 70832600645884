import TokenManager from "../helpers/TokenManager";
import store from "../store";
// import Vue from 'vue';
import VueGatesPlugin from '../plugins/vue-gates';

export default function AuthMiddleware({to,next}) {

    //console.log('authmiddle')
    // const tokenManager = new TokenManager();
    let token = TokenManager.getToken();
  
    if(token !== null && store.state.loggedIn){
        if(to.path == '/login'){
            next('/');
        }
        store.state.layout = 'dashboard'
        document.title = to.meta.title;
        
        next();
    }else{
        if(token !== null){
            store.$api.auth.me()
            .then((response) => {
                //$gates.setRoles(['writer']);
               
                if(!response.data){
                    store.state.loggedIn = false
                }
                else{
                   // console.log(response, 'ME'); 

                    store.$api.warehouse.getSelect()
                        .then((warehousesResponse) => {
                            // this.serverItems.warehouses = resp.data;
                            store.state.loggedIn = true
                            store.state.loggedUsername = response.data.name;
                            let warehousesList = [{name: "NA", slug: "NA"}];
                        
                            if(response.data.warehouses != null){
                                warehousesList = [];
                                if(response.data.type_warehouses == "all"){
                                    warehousesList = [{name: "Todas", slug: "all"}];
                                    warehousesList.push(...warehousesResponse.data.map((item) => {return{name:item.text, slug:item.value}}));
                                }else{
                                    warehousesList.push(...response.data.warehouses.map((item) => {return{name:item.text, slug:item.value}}));
                                }
                                
                                if(warehousesList.length == 0)
                                    warehousesList = [{name: "NA", slug: "NA"}];
                            }

                            store.state.userData = {
                                ...store.state.userData, 
                                role: {name: "user-role", permissions: response.data.permissions}, 
                                warehouses: warehousesList,
                                warehousesRaw: response.data.warehouses
                            };
                                
                            //store.state.userData = response.data;
                            VueGatesPlugin.init();
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .finally(() => {
                            if(store.state.loggedIn){
                                if(to.meta.auth){
                                    store.state.layout = 'dashboard'
                                    document.title = to.meta.title
                                    next();
                                }else{
                                    store.state.layout = 'dashboard'
                                    next('/');
                                }
                            }else{
                                if(to.meta.auth){
                                    store.state.layout = 'login'
                                    next('login');
                                }else{
                                    store.state.layout = 'login'
                                    next()
                                }
                            }
                        })
                    ;
                    
                   
                }
                
               
                
            })
            .catch((error) => {
              console.log(error, to)
           
                  TokenManager.removeToken();
                  store.state.layout = 'login'
                  next('login')
                  return;
           
            })
        }else{
            if(to.meta.auth){
                store.state.layout = 'login'
                next('login')
            }else{
                store.state.layout = 'login'
                next()
            }
        }
    }
}


