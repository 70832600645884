import AuthService from "./AuthService";
import UserService from "./UserService";
import RolService from "./RolService";
import ConsumableService from "./ConsumableService";
import CatalogService from "./CatalogServices";
import SupplierService from "./SupplierService";
import EquipmentService from "./EquipmentService";
import EntryOrderService from "./EntryOrderService";
import PurchaseOrderService from "./PurchaseOrderService";
import AddressAPIService from "./AddressAPIService";
import RequisitionService from "./RequisitionService";
import ProspectService from "./ProspectService";
import FeeService from "./FeeService";
import WarehouseService from "./WarehouseService";
import ServiceService from "./ServiceService";
import ClientService from "./ClientService";
import ScheduleService from "./ScheduleService";
import QuotationService from "./QuotationService";
import BanxioSIEAPIService from "./BanxioSIEAPIService";
import SaleOrderService from "./SaleOrderService";
import BillingService from "./BillingService";
import BankService from "./BankService"
import DepartmentService from "./DepartmentService";
import PackagingService from "./PackagingService";
import TypeService from "./TypeService";
import UnitService from "./UnitService";
import SupervisionService from "./SupervisionService";
import DashboardService from "./DashboardService";
import SupplyService from "./SupplyService";

export const $api = {
   
    auth: new AuthService(),
    user: new UserService(),
    rol: new RolService(),
    consumable: new ConsumableService(),
    catalog: new CatalogService(),
    supplier: new SupplierService(),
    equipment: new EquipmentService(),
    entryOrder: new EntryOrderService(),
    purchaseOrder: new PurchaseOrderService(),
    addressApi: new AddressAPIService(),
    requisition: new RequisitionService(),
    prospect: new ProspectService(),
    fee: new FeeService(),
    warehouse: new WarehouseService(),
    service: new ServiceService(),
    client: new ClientService(),
    schedule: new ScheduleService(),
    quotation: new QuotationService(),
    banxioSIE: new BanxioSIEAPIService(),
    saleOrder: new SaleOrderService(),
    billing: new BillingService(),
    bank: new BankService(),
    department: new DepartmentService(),
    packaging: new PackagingService(),
    type: new TypeService(),
    unit: new UnitService(),
    supervision: new SupervisionService(),
    dashboard: new DashboardService(),
    supply: new SupplyService(),
};