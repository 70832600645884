<template>
    <v-textarea
        :label="label"
        :name="nameInput"
        color="success"
        v-model="value" 
        hide-details="auto" 
        underlined 
        dense 
        rows="3"
        :rules="rule"
        :error-messages="errorMessages"
        :counter="counter"
    ></v-textarea>

</template>
<script>
export default {
    props: ['valueInput', 'rule', 'label', 'errorMessages', 'counter', 'nameInput'],
    model: {
        prop: 'value',
        event: 'valueChange'
    },
    computed: {
        value: {
            get: function() {
                return this.valueInput
            },
            set: function(value) {
                this.$emit('valueChange', value)
            }
        }
    }
}
</script>