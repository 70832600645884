<template>
   <div>
       <v-autocomplete 
        :label="label" 
        color="success" 
        v-model="value" 
        hide-details="auto" 
        :items="items" 
        underlined
        dense
        item-color="secondary"
        :rules="rule"
        :disabled="disabled"
        :error-messages="errorMessages"
        :return-object="returnObject"
       
        >
        
        </v-autocomplete>
        <v-progress-linear color="primary" indeterminate  height="2" v-if="loading"></v-progress-linear>
   </div>
</template>
<script>
export default {
    props: ['referenceInput','valueInput', 'rule', 'label', 'items', 'loading', 'disabled', 'errorMessages', 'returnObject'],
    model: {
        prop: 'value',
        event: 'valueChange'
    },
    computed: {
        value: {
            get: function() {
                return this.valueInput
            },
            set: function(value) {
                
                this.$emit('valueChange', value);
                
            }
        },
        
    },
}
</script>

<style lang="scss">

    //remove if label is removed
    // .v-input .v-progress-linear{
    //     background-color: var(--v-primary-lighten4)  !important;
    //     border-color: var(--v-primary-base)  !important;
    // }
    // .v-progress-linear .v-progress-linear__indeterminate--active .short{
    //     background-color: var(--v-primary-base) !important;
    //     border-color: var(--v-primary-base) !important;
    // }
    // .v-progress-linear .v-progress-linear__indeterminate--active .long{
    //     background-color: var(--v-primary-base) !important;
    //     border-color: var(--v-primary-base) !important;
       
    // }
    
</style>