import BaseApi from "./BaseApi";

export default class SupplyService extends BaseApi {


    constructor(){
        super();
    }


    async list()
    {
        return this.http.get(`${this.baseUrl}/supplies`)
        .catch(this.handleErrorResponse);
    }


    async get(id)
    {
        return this.http.get(`${this.baseUrl}/supplies/${id}/edit`)
        .catch(this.handleErrorResponse);
    }


    async store(data)
    {
        return this.http.post(`${this.baseUrl}/supplies/store`,data)
        .catch(this.handleErrorResponse);
    }


    async update(id, data)
    {
        return this.http.put(`${this.baseUrl}/supplies/${id}/update`, data)
        .catch(this.handleErrorResponse);
    }


    async delete(id)
    {
        return this.http.delete(`${this.baseUrl}/supplies/${id}/delete`)
        .catch(this.handleErrorResponse);
    }

    async getSelect()
    {
        return this.http.get(`${this.baseUrl}/getSupplies`)
        .catch(this.handleErrorResponse);
    }

}