
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class SupplierService extends BaseApi {


    constructor(){
        super();
    }


    async list()
    {
        return this.http.get(`${this.baseUrl}/suppliers`)
        .catch(this.handleErrorResponse);
    }


    async get(slug)
    {
        return this.http.get(`${this.baseUrl}/suppliers/${slug}/edit`)
        .catch(this.handleErrorResponse);
    }


    async store(data)
    {
        return this.http.post(`${this.baseUrl}/suppliers/store`,data)
        .catch(this.handleErrorResponse);
    }


    async update(slug, data)
    {
        return this.http.put(`${this.baseUrl}/suppliers/${slug}/update`, data)
        .catch(this.handleErrorResponse);
    }



    async delete(slug)
    {
        return this.http.delete(`${this.baseUrl}/suppliers/${slug}/delete`)
        .catch(this.handleErrorResponse);
    }


    async getSelect()
    {
        return this.http.get(`${this.baseUrl}/getSuppliers`)
        .catch(this.handleErrorResponse);
    }


    async getProducts(suppplier)
    {
        return this.http.get(`${this.baseUrl}/getProducts/${suppplier}`)
        .catch(this.handleErrorResponse);
    }
}