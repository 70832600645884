<template>
    <div>
        <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
        
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            color="success"
            
            underlined
            dense
            v-model="value"
            :label="label"
            append-icon="mdi-calendar"
            hide-details="auto" 
            v-bind="attrs"
            v-on="on"
            :rules="rule"
            :error-messages="errorMessages"
            
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="value"
          @input="menu = false"
          :min="minIn"
          :max="maxIn"
        ></v-date-picker>
      </v-menu>
    </div>
</template>

<script>
export default {
    props: ['valueInput', 'rule', 'label', 'errorMessages', 'minIn', 'maxIn'],
    model: {
        prop: 'value',
        event: 'valueChange'
    },
    computed: {
        value: {
            get: function() {
                return this.valueInput
            },
            set: function(value) {
                this.$emit('valueChange', value)
            }
        }
    },
    data: () =>({
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
    })
}
</script>
<style lang="scss" >
   
  
</style>