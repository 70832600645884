
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class BillingService extends BaseApi {


    constructor(){
        super();
    }

    async list(warehouse)
    {
        return this.http.get(`${this.baseUrl}/billings/${warehouse}`)
        .catch(this.handleErrorResponse);
    }

    async get(id, startDate, endDate, clientId, billingId)
    {
        let url = `${this.baseUrl}/billings/${startDate}/${endDate}/${clientId}/${id}`;
        if(billingId != "")
            url =`${this.baseUrl}/billings/${startDate}/${endDate}/${clientId}/${id}/${billingId}`;
        return this.http.get(url)
        .catch(this.handleErrorResponse);
    }

    async getConditions(id, startDate, endDate, clientId)
    {
        return this.http.get(`${this.baseUrl}/billings/getConditions/${startDate}/${endDate}/${clientId}/${id}`)
        .catch(this.handleErrorResponse);
    }

    async storeConditions(id, startDate, endDate, clientId, data)
    {
        return this.http.post(`${this.baseUrl}/billings/${startDate}/${endDate}/${clientId}/${id}/conditions`,data)
        .catch(this.handleErrorResponse);
    }

    async update(sku, data)
    {
        return this.http.put(`${this.baseUrl}/billings/${sku}/update`, data)
        .catch(this.handleErrorResponse);
    }

  
    async getCutoff(client_id)
    {
        return this.http.get(`${this.baseUrl}/getCutoff/${client_id}`)
        .catch(this.handleErrorResponse);
    }


    async pdf(id, startDate, endDate, clientId)
    {
        return this.http.get(`${this.baseUrl}/billings/pdf/${startDate}/${endDate}/${clientId}/${id}`)
        .catch(this.handleErrorResponse);
    }


    async store(data)
    {
        return this.http.post(`${this.baseUrl}/billings/store`, data)
        .catch(this.handleErrorResponse);
    }

    async delete(id)
    {
        return this.http.delete(`${this.baseUrl}/billings/${id}/delete`)
        .catch(this.handleErrorResponse);
    }



   

}